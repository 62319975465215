html,
body {
  margin: 0;
  padding: 0;
  @include theme-background('light');
  @include theme-text-color('light');
  font-family: 'Inter Tight', sans-serif;
  scroll-behavior: smooth;
  color: $text-color;
  background-color: $background;

  .logo {
    display: inline;
    height: 130px;
    border-radius: 100%;
    margin-bottom: 1em;
  }

  hr {
    margin: 75px auto;
    width: 100px;
    text-align: center;
  }

  footer {
    text-align: center;
    padding-bottom: 1em;
  }
}

.fc {
  font-family: 'Inter Tight', sans-serif;
}

.fc-h-event {
  background-color: #01352c;
}

.fc .fc-button-primary {
  background-color: #004d3f;
  border-color: #004d3f;

  &.fc-button-active {
    background-color: #01352c;
    border-color: #01352c;
  }
}

@media screen and (max-width: 768px) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: column;
  }

  .fc-toolbar-chunk {
    margin-bottom: 1em;
  }
}

// DARK MODE
//
// @media (prefers-color-scheme: light) {
//   html,
//   body {
//     @include theme-background("light");
//     @include theme-text-color("light");
//   }
// }
