$breakpoints: (
  'md': 765px,
  'lg': 1200px,
  'xl': 1600px,
);

$colors: (
  'primary': #131a2a,
  'secondary': #f5f5f5,
  'white': #ffffff,
  'background': #ffc007,
);

$background: map-get($colors, 'background');
$text-color: map-get($colors, 'primary');

$theme: (
  'light': (
    'background': map-get($colors, 'secondary'),
    'text': map-get($colors, 'primary'),
    'lines': map-get($colors, 'primary'),
  ),
  'dark': (
    'background': map-get($colors, 'primary'),
    'text': map-get($colors, 'secondary'),
    'lines': map-get($colors, 'secondary'),
  ),
);

@mixin theme-background($type) {
  background: map-get(map-get($theme, $type), 'background');
}

@mixin theme-text-color($type) {
  color: map-get(map-get($theme, $type), 'text');
}

@mixin theme-lines-color($type) {
  border-color: map-get(map-get($theme, $type), 'lines');
  border-bottom-color: map-get(map-get($theme, $type), 'lines');
}
