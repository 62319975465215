.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0;

  &.fluid {
    max-width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    [class^='col-'],
    [class*=' col-'] {
      // margin: 0;
      // padding: 1em;
      box-sizing: border-box;
    }

    @for $i from 1 through 12 {
      $width: '#{(100 / 12) * $i}%';
      .col-#{$i} {
        width: unquote($width);
      }
    }

    @each $key, $breakpoint in $breakpoints {
      @media screen and (min-width: $breakpoint) {
        @for $i from 1 through 12 {
          $width: '#{(100 / 12) * $i}%';
          .col-#{$key}-#{$i} {
            width: unquote($width);
          }
        }
      }
    }
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }

  @for $i from 0 through 12 {
    $width: '#{$i}em';
    .mr-#{$i} {
      margin-right: unquote($width);
    }

    .mb-#{$i} {
      margin-bottom: unquote($width);
    }

    .ml-#{$i} {
      margin-left: unquote($width);
    }

    .mt-#{$i} {
      margin-top: unquote($width);
    }

    .m-#{$i} {
      margin: unquote($width);
    }

    .mx-#{$i} {
      margin-left: unquote($width);
      margin-right: unquote($width);
    }

    .my-#{$i} {
      margin-top: unquote($width);
      margin-bottom: unquote($width);
    }

    .px-#{$i} {
      padding-left: unquote($width);
      padding-right: unquote($width);
    }

    .py-#{$i} {
      padding-top: unquote($width);
      padding-bottom: unquote($width);
    }

    .pr-#{$i} {
      padding-right: unquote($width);
    }

    .pb-#{$i} {
      padding-bottom: unquote($width);
    }

    .pl-#{$i} {
      padding-left: unquote($width);
    }

    .pt-#{$i} {
      padding-top: unquote($width);
    }

    .p-#{$i} {
      padding: unquote($width);
    }
  }

  .flex {
    display: flex;

    &.flex__center {
      align-items: center;
      justify-content: center;
    }

    &.justify__end {
      justify-content: flex-end;
    }

    &.flex__column {
      flex-direction: column;
    }

    &.align__between {
      align-items: space-between;
    }

    &.justify__between {
      justify-items: space-between;
    }
  }

  .round {
    border-radius: 500%;
  }

  ul {
    li {
      display: inline-block;
      list-style: none;
    }
  }

  .text-right {
    text-align: right;
  }
}
